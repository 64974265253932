import styled from 'styled-components'
import { BlogThreshold as Threshold } from '../../constants/theme'
import Colors from '../../constants/colors'

export const SmartAppBannerWrapper = styled.div`
  display: none;

  @media (max-width: ${Threshold.tablet}) {
    position: absolute;
    bottom: 0px;
    z-index: 101;

    display: block;
    width: 100%;
    overflow-x:hidden;

    .smartbanner {
      width: 100%;
      background-color: #343536;
      padding: 20px 10px;

      a {
        color: #3452ff;
        text-decoration: none;

        :active {
          text-decoration: underline;
        }
      }

      .smartbanner-close {
        padding: 0;
        border: none;
        background-color: transparent;
        color: #8e8e8e;
        font-size: 25px;
        outline: 0;
        width: 20px;
        height: 20px;
      }

      .smartbanner-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .smartbanner-icon {
          background-repeat: no-repeat;
          width: 49px;
          height: 49px;
          border-radius: 10px;
          margin: 0 20px;
        }
        .smartbanner-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 25px;
          max-width: 39vw;

          .smartbanner-author {
            font-size: 15px;
            color: #8e8e8e;
          }

          .smartbanner-description {
            display: none;
          }
        }
      }
    }
  }
  @media not all and (min-resolution: 0.001dpcm) {
    display: none;
  }
`
