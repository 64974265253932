import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { inject, observer } from 'mobx-react'

import Cookies from 'cookies-js'
import Button from '../Button'
import { withTranslation } from '../../i18n'

import { ContentDiv, GlobalStyle } from './styles'


@inject('store')
@observer
class LogoutModal extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object).isRequired
  }

  render() {
    const {
      t,
      store: { logoutModal, toggleLogoutModal, logout }
    } = this.props

    return (
      <>
        <GlobalStyle />
        <ReactModal
          ariaHideApp={false}
          isOpen={logoutModal.isOpen}
          onRequestClose={() => {
            toggleLogoutModal(false)
          }}
          overlayClassName="LogoutModal_Overlay"
          className="LogoutModal_Content"
        >
          <ContentDiv>
            <span className="message">{t('LOGOUT_QUESTION')}</span>
            <div className="buttons">
              <Button
                className="button"
                onPress={() => {
                  Cookies.expire('userToken')
                  logout()
                }}
              >
                {t('YES')}
              </Button>
              <Button
                className="button"
                negative
                onPress={() => {
                  toggleLogoutModal(false)
                }}
              >
                {t('NO')}
              </Button>
            </div>
          </ContentDiv>
        </ReactModal>
      </>
    )
  }
}

export default withTranslation(['auth'])(LogoutModal)
