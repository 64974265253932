/* eslint-disable max-len */
import PropTypes from 'prop-types'
export const Doubletapp = ({ whiteFill, className }) => (

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538.11 46.91" className={className}>
    <g id="Layer_2" data-name="Layer 2">
      <g id="LOGO">
        <g id="DOUBLETAPP_logo_on_black">
          <circle  cx="524" cy="32.8" r="14.11" fill="#e61e32"/>
          <path
            fill={whiteFill ? '#fff' : '#000'}
            
            d="M139.5,46.83c-15.64,0-23.57-7.88-23.57-23.41V.8h13V23.42c0,8.23,3,11.44,10.6,11.44s10.61-3.32,10.61-11.44l0-22.62h12.94V23.42C163.08,39,155.15,46.83,139.5,46.83Zm-56.87,0c-10.25,0-27.44-3-27.44-23.41C55.19,7.88,64.42,0,82.63,0s27.45,7.88,27.45,23.42C110.08,43.79,92.88,46.83,82.63,46.83Zm0-34.85c-7.26,0-14,1.38-14,11.44,0,8,4.19,11.44,14,11.44s14-3.53,14-11.44C96.63,13.28,89.15,12,82.63,12Zm88.68,34.11V.8h23.42c12.79,0,19.49,3.72,19.93,11,.31,5.18-2.15,8.39-7.5,9.82l-.42.12v1.58l.49.07c2.93.36,9.73,2,9.73,9.61,0,12-12.08,13-25.53,13Zm12.73-10h7.76c5.15,0,8.92-.36,10.54-2.14a3.7,3.7,0,0,0,.87-3c-.34-3.59-4.63-3.85-11.66-3.85h-7.44ZM184,19.22h7.08c5.43,0,9.93-.31,10.12-4.1a3.43,3.43,0,0,0-.86-2.65c-1.35-1.42-4.13-1.7-8.18-1.7h-8.09ZM269.12.8h37.72V11.68h-25l-.08,7.46h22.38v8.64H281.92l-.07,7.36h26V46c-4.64,0-34.24.06-38.72.06ZM328.24,46V12.34H313V.8h43.3V12.34H341.09V46ZM223.37,46V.8h12.74V34.49H262V46ZM0,46V.8H25.82C42.55.8,50.68,8.2,50.68,23.42,50.68,42.1,37.16,46,25.82,46ZM12.73,34.49H25.51c8.4,0,12.15-3.56,12.15-11.55,0-9.32-5.84-10.6-12.15-10.6H12.73ZM464.05,45.6V.31h23.43c13.61,0,19.95,5.26,19.95,16.53,0,11.49-6.48,16.38-21.69,16.38h-9l.07,12.38Zm12.67-21.78h7.09c4.71,0,10.11-.76,10.11-6.65,0-4.76-2.5-6.45-9.56-6.45h-7.57ZM414.08,45.6V.31H437.5c13.62,0,20,5.26,20,16.53,0,11.49-6.49,16.38-21.69,16.38h-9l.06,12.38Zm12.67-21.78h7.08c4.71,0,10.12-.76,10.12-6.65,0-4.76-2.5-6.45-9.56-6.45h-7.57Zm-33,21.78-3.15-7.75H369.81l-3.16,7.75H351.88L371.12.49h18.2L408.57,45.6ZM373.2,29.21h14.05l-7-18.26Z"
          />
        </g>
      </g>
    </g>
  </svg>
)

Doubletapp.propTypes = {
  whiteFill: PropTypes.bool,
  className: PropTypes.string
}

Doubletapp.defaultProps = {
  whiteFill: false,
  className: undefined
}
