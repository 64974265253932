const hiddenPages = ['/podcast', '/mag']


type Page =  {
  link: string;
  title: string;
  as?: undefined;
} | {
  link: string;
  as: string;
  title: string;
}
export const pageListFilterByLang = (lang: string, page: Page) => {
   return hiddenPages.every((hiddenPage) =>  page.link !== hiddenPage) || lang !== 'en'
}