import styled from 'styled-components'
import Colors from '../../constants/colors'

export const Content = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  overflow: hidden;
  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 768px) {
    padding-top: 50px;
  }
  .mobileHeader {
    position: absolute;
    top: 0;
    z-index: 3;
    width: 100vw;
    height: 80px;
    background-color: transparent;
    background-image: url('/static/images/footerMobile.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -5px;
  }
`

export const Button = styled.img`
  align-self: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 25px;
  cursor: pointer;
  border: none;
  color: #000;
  z-index: 10;
  @media (min-width: 769px) {
    display: none;
  }
  :focus {
    outline: none;
  }
  :hover {
    color: #933ec5;
  }
  .icon {
    width: 22px;
    height: 28px;
  }
`

export const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100vh;
  padding-left: 40px;
  padding-right: 25px;
`

export const Menu = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .topButtons {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 35px;
    .profileIcon {
      cursor: pointer;
      transition-duration: 0.2s;
      width: 30px;
      height: 30px;
      &:hover {
        transform: scale(1.1);
      }
    }
    .searchIcon {
      cursor: pointer;
      margin-left: 25px;
      margin-right: auto;
      transition-duration: 0.2s;
      width: 30px;
      height: 30px;
      &:hover {
        transform: scale(1.1);
      }
    }
    .avatar {
      object-fit: cover;
      border-radius: 15px;
    }
  }
  ${Button} {
    align-self: center;
    position: relative;
    margin-right: -15px;
    width: 22px;
    height: 22px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`

export const Language = styled.div`
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 2px;
  font-family: StolzlMedium, serif;
  margin-bottom: 30px;
  cursor: pointer;
  color: #933ec5;
  .full {
    font-family: StolzlLight, serif;
    color: #000;
  }
`

export const LinkTextA = styled.a`
  cursor: pointer;
  text-decoration: none;

  font-size: 16px;
  line-height: 30px;
  font-family: ${props => (props.selected ? 'StolzlMedium' : 'StolzlLight')};
  letter-spacing: ${props => (props.selected ? '1.5px' : '2px')};
  color: ${props => (props.selected ? Colors.primary : '#080707')};
  padding: 15px 0;
`

export const BackgroundImg = styled.img`
  position: absolute;
  width: 400vw;
  z-index: 1;
`
