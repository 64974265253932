import getConfig from 'next/config'

import { i18n } from '../i18n'

const { publicRuntimeConfig } = getConfig()


export const fetchButtonsData = async (language = i18n.language) => {
  let url = '/api/v1/buttons/'

  try {
    const res = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Accept-Language': language,
        Secret: publicRuntimeConfig.secret
      }
    })
    return await res.json()
  } catch (error) {
    console.error(error)
  }
  return null
}
