import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import { withTranslation, i18n, Link } from '../../i18n'

import { Doubletapp } from '../../public/static/svgs/Doubletapp'
import { Pdf } from '../../public/static/svgs/Pdf'
import SocialIcons from '../SocialIcons'
import {
  Container, FooterBack, ContactsDiv, CenterDiv, UMNImage, SocialsDiv
} from './styles'

class Footer extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    whiteStyle: PropTypes.bool
  }

  static defaultProps = {
    whiteStyle: false
  }

  state = {
    windowWidth: 1440
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    this.checkWindowWidth()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    this.checkWindowWidth()
    this.setState({ windowWidth: window.innerWidth })
  }

  checkWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  render() {
    const { t, whiteStyle } = this.props
    const width = this.state.windowWidth

    let linkSVGHeight = '48px'
    let linkSVGWidth = '145px'
    if (width <= 1000) {
      linkSVGHeight = '34px'
      linkSVGWidth = '98px'
    }

    return (
      <Container whiteStyle={whiteStyle}>
        {!whiteStyle && (
          <FooterBack
            src={width > 475 ? '/static/images/footer.png' : '/static/images/background-46.png'}
            alt=""
            srcSet={
              width > 475
                ? '/static/images/footer.png 1440w, /static/images/footer@2x.png 2880w'
                : '/static/images/background-46.png 1440w, /static/images/background-46@2x.png 2880w'
            }
          />
        )}
        <div className="footerContent">
          {width > 600 && (
            <ContactsDiv whiteStyle={whiteStyle}>
              <h3 className="header">{`${t('press-officer')}:`}</h3>
              <a className="line" href="mailto:press@uralmusicnight.ru" target="_blank" rel="noopener noreferrer">
              press@uralmusicnight.ru
              </a>
              <div className="grants">
                {t('with-grants')}
              </div>
            </ContactsDiv>
          )}
          <CenterDiv whiteStyle={whiteStyle}>
            <Link href="/">
              <UMNImage src={whiteStyle ? '/static/images/umn@3x.png' : '/static/images/umn-logo-white.png'} />
            </Link>
            <a className="doubletappLink" href="https://doubletapp.ai/" target="_blank" rel="noopener noreferrer">
              <span className="line flexLine">
                {`${t('made-by')} `}
                <Doubletapp whiteFill={!whiteStyle} className="logo" />
              </span>
            </a>
          </CenterDiv>
          {width > 600 && (
            <SocialsDiv whiteStyle={whiteStyle}>
              <SocialIcons
                socials={{
                  vkontakte: 'https://vk.com/uralmusicnight',
                  // facebook: 'https://www.facebook.com/uralmusicnight',
                  // instagram: 'https://www.instagram.com/uralmusicnight/',
                  telegram: 'https://t.me/uralmusicnight'
                }}
                className="icons"
                whiteFill={!whiteStyle}
              />
              <a className="pdfLink" target="_blank" href="https://disk.yandex.ru/i/4rKw8dHvfr0n2w">
                <span>{t('application-selection-rules')}</span>
                {' '}
                <Pdf />
              </a>
              <div className="stores">
                <a href={t('common:ios-link')} target="_blank" rel="noopener noreferrer">
                  <ReactSVG
                    svgStyle={{ height: linkSVGHeight, width: linkSVGWidth }}
                    className="link"
                    src={i18n.language === 'ru' ? '/static/images/app-store.svg' : '/static/images/app-store-en.svg'}
                  />
                </a>
                <a href={t('common:android-link')} target="_blank" rel="noopener noreferrer">
                  <ReactSVG
                    svgStyle={{ height: linkSVGHeight, width: linkSVGWidth }}
                    className="link"
                    src={
                      i18n.language === 'ru' ? '/static/images/google-play.svg' : '/static/images/google-play-en.svg'
                    }
                  />
                </a>
              </div>
            </SocialsDiv>
          )}
        </div>
        {/* {!whiteStyle && <BackExtension width={width} />} */}
      </Container>
    )
  }
}

export default withTranslation('contacts')(Footer)
