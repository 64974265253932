export default [
  { link: '/', title: 'PAGE_ABOUT_TITLE' },
  { link: '/broadcast', title: 'PAGE_BROADCAST_MENU_TITLE' },
  // { link: '/history-artists', title: 'PAGE_LONGLIST_MENU' },
  { link: '/history', title: 'PAGE_NEWS_TITLE' },
  { link: '/podcast', title: 'PAGE_PODCAST_TITLE' },
  { link: '/artists', title: 'PAGE_ARTISTS_TITLE' },
  { link: '/places', title: 'PAGE_PLACES_TITLE' },
  { link: '/partners', title: 'PAGE_PARTNERS_TITLE' },
  { link: '/contacts', title: 'PAGE_CONTACTS_TITLE' },
  { link: '/mag', title: 'PAGE_MAG_TITLE' },
  { link: '/program', title: 'PAGE_PROGRAM_TITLE' },
]
