/* eslint-disable max-len */
import PropTypes from 'prop-types'

export const Pdf = ({ style, className }) => (
  <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} className={className}>
    <path d="M1.9018 0H17.795L26.2191 8.39139V28.0585C26.2191 29.092 25.3812 29.9291 24.3486 29.9291H1.9018C0.869218 29.9291 0.03125 29.092 0.03125 28.0585V1.87055C0.03125 0.837097 0.869315 0 1.9018 0Z" fill="#934CBC"/>
    <path d="M26.1912 8.41739H19.6713C18.6387 8.41739 17.8008 7.57943 17.8008 6.54684V0.0185547L26.1912 8.41739Z" fill="#7F3CA6"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.45099 13.105H7.1575C6.79179 13.105 6.58789 13.3462 6.58789 13.7129V18.0984C6.58789 18.4894 6.8217 18.7064 7.13592 18.7064C7.45015 18.7064 7.68396 18.4894 7.68396 18.0984V16.818H8.49483C9.49557 16.818 10.3214 16.109 10.3214 14.9689C10.3215 13.8532 9.5247 13.105 8.45099 13.105ZM13.1713 13.105H11.8657C11.5112 13.105 11.2596 13.3482 11.2596 13.7091V18.1022C11.2596 18.5502 11.6215 18.6905 11.8806 18.6905H13.2508C14.8725 18.6905 15.9434 17.6234 15.9434 15.9763C15.9426 14.2348 14.9344 13.105 13.1713 13.105ZM19.203 14.1815C19.5163 14.1815 19.6697 13.9084 19.6697 13.6437C19.6697 13.3697 19.5098 13.105 19.203 13.105H17.4185C17.0696 13.105 16.8751 13.3939 16.8751 13.7129V18.0984C16.8751 18.4894 17.0977 18.7064 17.3988 18.7064C17.6981 18.7064 17.9217 18.4894 17.9217 18.0984V16.8948H19.001C19.3359 16.8948 19.5033 16.6207 19.5033 16.3486C19.5033 16.082 19.3359 15.8173 19.001 15.8173H17.9217V14.1815H19.203ZM12.4381 17.6075H13.2341C14.2497 17.6075 14.7136 16.863 14.7136 15.9221C14.7136 14.9167 14.2414 14.1881 13.1555 14.1881H12.4381V17.6075ZM7.68405 15.7893H8.4295C8.88966 15.7893 9.18144 15.4302 9.18241 14.9625C9.18241 14.4939 8.88966 14.1348 8.4295 14.1348H7.68405V15.7893Z" fill="white"/>
  </svg>
)

Pdf.propTypes = {
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string
}

Pdf.defaultProps = {
  style: {},
  className: undefined
}
