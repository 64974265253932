import React from 'react'
import SmartBanner from 'react-smartbanner'
import { useTranslation } from '../../i18n'
import * as SC from './styles'

const SmartAppBanner = ({hideBanner}) => {
  const {t} = useTranslation(['common'])
  return (
    <SC.SmartAppBannerWrapper>
      <SmartBanner
        title={t('common:smart-app-banner')}
        force="android"
        daysHidden={0}
        onClose={hideBanner}
        author='Ural Music Night'
      >
      </SmartBanner>
    </SC.SmartAppBannerWrapper>
  )
}

export default SmartAppBanner
