import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  .SearchModal_Overlay {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.70);
    z-index: 100;
    &::after {
      background-image: url('/static/images/cross-circle.svg');
      background-size: 50px 50px;
      content: "";
      width: 50px;
      height: 50px;
      position: absolute;
      right: 50px;
      top: 75px;
      cursor: pointer;
      @media (max-width: 700px) {
        display: none;
      }
    }
  }
  .SearchModal_Content {
    margin-bottom: 250px;
    border: 0px solid #fff;
    border-bottom-width: 2px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    max-width: 80vw;
  }
`

export const ContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 2px;
  .input {
    background-color: transparent;
    border: 0px;
    border-radius: 0px;
    box-shadow: none;
    input {
      background-color: transparent;
      opacity: 0.6;
      font-family: StolzlMedium;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 2px;
      color: #fff;
      width: 100%;
      ::placeholder {
        color: #fff;
      }

      @media (max-width: 425px) {
        font-size: 16px;
        margin-left: 0;
      }
    }
  }
`
